// Note: Requires flexslider 2 buddy.
jQuery(document).ready(function($) {
	
	if ( $('.vertical-slider-wrapper-images .flexslider').length ) {
		// Flexslider
		$('.vertical-slider-wrapper-images .flexslider').flexslider({
			animation: "slide",
			prevText: "",
			nextText: "",
			controlNav: true,
			directionNav: true,
			customDirectionNav: $(".vertical-slider-custom-navigation a"),
		});
	}

}); /* end of as page load scripts */